<template>
    <div class="card-tail self-start min-h-[700px] sticky top-0" v-loading="$waiting.is('loading.found_invoicess')">
        <AvstamningFoundInvoicesTable :invoices-data="invoicesData" @editInvoice="openEditInvoiceModal" />
    </div>
</template>
<script>
import Api from "../avstamning.api";

export default {
    props: {
        paymentId: {
            type: Number,
            default: 0,
        },
        refreshCounter: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            invoicesData: [],
            selectedInvoiceId: null,
        };
    },

    components: {
        AvstamningFoundInvoicesTable: () => import("./AvstamningFoundInvoicesTable"),
    },

    watch: {
        paymentId() {
            if (!this.paymentId) return;
            this.getDetails();
        },
        refreshCounter() {
            this.invoicesData = [];
        },
    },

    methods: {
        async getDetails() {
            this.$waiting.start("loading.found_invoices");
            this.invoicesData = await Api.getPaymentMatchedInvoices(this.paymentId);
            window.scrollBy(0, -1); // Ultimate solution for browser quirk while using position sticky
            this.$waiting.end("loading.found_invoices");
        },

        openEditInvoiceModal(id) {
            this.$emit("showEditInvoiceModal", id);
        },
    },
};
</script>
